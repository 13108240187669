import intlTelInput from "intl-tel-input";

export function setValidationRules() {
    jQuery.validator.addMethod("phone", function(value, element) {
        return intlTelInput.getInstance(element).isValidNumber();
    }, "Please enter a valid phone number");

    jQuery.validator.addMethod("validateEmail", function(value) {
        return /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
    }, "Please enter valid email address!");

    jQuery.validator.addMethod("greaterThan", function(value, element, params) {
        if (!/Invalid|NaN/.test(new Date(value))) {
            return new Date(value) > new Date($(params).val());
        }

        return !isNaN(value) && !isNaN($(params).val()) || (Number(value) > Number($(params).val()));
    }, "Must be greater than {0}.");

    jQuery.validator.addMethod("lessThan", function(value, element, params) {
        if (!/Invalid|NaN/.test(new Date(value))) {
            return new Date(value) < new Date($(params).val());
        }

        return !isNaN(value) && !isNaN($(params).val()) || (Number(value) > Number($(params).val()));
    }, "Must be less than {0}.");
}

export function setInputSanitizer() {
    $("input, textarea").on('keyup blur', function () {
        const element = $(this);
        const sanitized = element.val().replace(/<(|\/|[^>\/bi]|\/[^>bi]|[^\/>][^>]+|\/[^>][^>]+)>/g, '');
        element.val(sanitized);
    });
}